import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ScrollToTop from './components/shared/scroll-to-top';

import App from './App';
import { ProfileProvider } from './context/profile-context/profile-context';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <ProfileProvider>
      <App />
      <ToastContainer
        position="bottom-right"
        theme="colored"
        className="react-toast-container"
      />
    </ProfileProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
